export const colorPalette = {
  white: '#ffffff',
  darkGrey: '#3C3D41',
  red: '#E30613',
  lightGreen: '#B2E76A',
  darkGreen: '#006954',
  paleGreen: '#CCE1DD',
  lightGrey: '#F1EDE7',
  veryLightGreen: '#E6F0EE',
  midGrey: '#222222',
  hoverGreen: '#82ba42',
  buttonGreen: '#A3E953',
  borderColor: 'rgba(2, 49, 91, 0.75)',
};

// Then you can reference the colors in your components object like this:

export const components = {
  global: {
    focusColor: colorPalette.darkGrey,
    invalid: colorPalette.red,
    valid: colorPalette.lightGreen,
  },

  header: {
    background: colorPalette.white,
    textColor: colorPalette.darkGreen,
    textTransform: 'uppercase',
  },

  footer: {
    background: colorPalette.darkGreen,
    textColor: colorPalette.paleGreen,
  },

  section: {
    defaultBackground: colorPalette.lightGrey,
  },

  pageHeader: {
    background: colorPalette.lightGrey,
    textColor: colorPalette.darkGrey,
  },

  tabs: {
    background: colorPalette.darkGreen,
    textColor: colorPalette.white,
    borderColor: colorPalette.borderColor,
    borderRadius: '0px',
    containerBackground: colorPalette.white,
    activeBackground: colorPalette.white,
  },

  text: {
    defaultColor: colorPalette.darkGrey,
  },

  // Form steps

  formStep: {
    buttonColor: colorPalette.darkGrey,
    line: `url("data:image/svg+xml,%3Csvg width='2' height='1472' viewBox='0 0 2 1472' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23${colorPalette.darkGreen.replace(
      '#',
      ''
    )}' stroke-width='2' stroke-linecap='round' stroke-dasharray='2 4' d='M1 1v1470'/%3E%3C/svg%3E");`,
    circle: {
      background: colorPalette.darkGreen,
      textColor: colorPalette.white,
    },
  },

  panel: {
    defaultBackground: colorPalette.lightGrey,
    headerBackground: colorPalette.darkGreen,
    headerTextColor: colorPalette.white,
  },

  // Form field panel

  formFieldPanel: {
    background: colorPalette.lightGrey,
    borderRadius: '4px',
  },

  // Info

  info: {
    background: colorPalette.veryLightGreen,
    border: 'none',
    borderRadius: '4px',
  },

  // Form base

  formBase: {
    hoverShadow: `${colorPalette.darkGrey} 0px 0px 0px 1px`,
    valid: `2px solid ##4e9c33`,
    borderColor: colorPalette.darkGrey,
    invalid: colorPalette.red,
    borderRadius: '4px',
    selectArrow: `url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.256 1a.727.727 0 0 0-.506.223L8.998 6.976l-6.75-5.753A.727.727 0 1 0 1.22 2.251l7.265 6.266a.726.726 0 0 0 1.027 0l7.265-6.266a.727.727 0 0 0-.52-1.25Z' fill='%23005AA0' stroke='%23005AA0'/%3E%3C/svg%3E")`,
  },

  // Filter input

  filterInput: {
    hoverBackground: colorPalette.lightGrey,
    hoverColor: colorPalette.darkGrey,
    hoverTextDecoration: 'none',
  },

  // Button select

  buttonSelect: {
    hasIcon: false,
    selectedBackground: colorPalette.darkGreen,
  },

  // Edit panel

  editPanel: {
    background: colorPalette.lightGrey,
  },

  // File input
  fileUpload: {
    borderColor: colorPalette.midGrey,
  },

  // Loader

  loader: {
    color: colorPalette.darkGreen,
  },

  // Buttons

  buttons: {
    solid: {
      border: colorPalette.buttonGreen,
      background: colorPalette.buttonGreen,
      color: colorPalette.darkGreen,
      uppercase: true,
      underline: false,
      hover: {
        border: colorPalette.hoverGreen,
        background: colorPalette.hoverGreen,
        color: colorPalette.darkGreen,
        underline: false,
      },
      disabled: {
        border: colorPalette.veryLightGreen,
        background: colorPalette.veryLightGreen,
        color: colorPalette.darkGreen,
      },
    },
    outline: {
      border: colorPalette.darkGreen,
      background: colorPalette.white,
      color: colorPalette.darkGreen,
      uppercase: true,
      underline: false,
      hover: {
        border: colorPalette.darkGreen,
        background: colorPalette.darkGreen,
        color: colorPalette.white,
        underline: false,
      },
    },
  },

  // Checkbox

  checkbox: {
    border: colorPalette.darkGreen,
  },
};

export default components;

export type ThemeComponents = typeof components;
export type ThemeComponentsKeys = keyof typeof components;
