import React from 'react';
import { Script, ScriptStrategy } from 'gatsby';
import { isProd } from '../client';

export const ScriptsBefore = () => (
  <>
    {/* // OneTrust Cookies Consent Notice */}
    <Script
      strategy={ScriptStrategy.postHydrate}
      type="text/javascript"
      src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
      charSet="UTF-8"
      data-domain-script={`957e6e5e-b4c1-4ba8-a9eb-242288bae1c5${!isProd ? '-test' : ''}`}
    />
    <Script
      strategy={ScriptStrategy.postHydrate}
      type="text/javascript"
      dangerouslySetInnerHTML={{ __html: 'function OptanonWrapper() {}' }}
    />
    {/* GTM */}
    <Script strategy={ScriptStrategy.postHydrate}>
      {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KFNX8D');
      `}
    </Script>
  </>
);
