import { ThemeBordersKeys } from './borders';
import { ThemeColorsKeys } from './colors';
import { ThemeFontsKeys } from './typography';

const buttons = {
  solid: {
    type: 'smallButton',
    border: 'green',
    background: 'green',
    color: 'darkPrimary',
    uppercase: true,
    hover: {
      border: 'darkGreen',
      background: 'darkGreen',
    },
    disabled: {
      border: 'lightGreen',
      background: 'lightGreen',
    },
    animate: true,
  },
  link: {
    type: 'fieldLabel',
    border: 'transparent',
    background: 'transparent',
    color: 'darkBlue',
    underline: true,
    hover: {
      border: 'transparent',
      background: 'transparent',
      underline: true,
    },
    disabled: {
      color: 'lightGreen',
    },
  },
  outline: {
    type: 'fieldLabel',
    border: 'green',
    background: 'transparent',
    color: 'green',
    hover: {
      border: 'darkGreen',
      background: 'transparent',
      color: 'darkGreen',
    },
    disabled: {
      border: 'lightGreen',
      background: 'white',
      color: 'lightGreen',
    },
  },
};

export default buttons;

interface ThemeButtonBase {
  color: ThemeColorsKeys;
  background: ThemeColorsKeys;
  border: ThemeBordersKeys;
  underline?: boolean;
  uppercase?: boolean;
  animate?: boolean;
}
interface ThemeButton extends ThemeButtonBase {
  type: ThemeFontsKeys;
  hover: ThemeButtonBase;
  disabled: ThemeButtonBase;
  selected?: ThemeButtonBase;
}
export type ThemeButtonKeys = keyof typeof buttons;
export type ThemeButtons = Record<ThemeButtonKeys, ThemeButton>;
